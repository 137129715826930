<h1 mat-dialog-title>End-User License Agreement (EULA) of Mellon</h1>
<mat-dialog-content>
  <h2>Acceptance of the Agreement</h2>
  <p>
    By accessing and using Quant IP Mellon, you accept and agree to be bound by
    the terms and provision of this agreement.
  </p>
  <h2>License</h2>
  <p>
    Quant IP GmbH grants you a revocable, non-exclusive, non-transferable,
    limited license to use the software strictly in accordance with the terms of
    this Agreement.
  </p>
  <h2>Restrictions</h2>
  <p>
    You agree not to, and you will not permit others to license, sell, rent,
    lease, assign, distribute, transmit, host, outsource, disclose, reverse
    engineer or otherwise commercially exploit the software or make the software
    available to any third party.
  </p>
  <p>
    This license and information provided through the usage of Mellon is
    provided by Quant IP GmbH, Nymphenburger Straße 5, 80335 (hereinafter
    referred to as “Quant IP GmbH”) to institutional investors only. It must not
    be provided to any other third party, including retail clients. Any analysis
    and output generated is for information purposes only and does not take into
    account specific circumstances of any recipient.
  </p>

  <h2>Modifications to Software</h2>
  <p>
    Quant IP GmbH reserves the right to modify, suspend or discontinue,
    temporarily or permanently, the software or any service to which it
    connects, with or without notice and without liability to you.
  </p>
  <h2>Disclaimer of Warranty</h2>
  <p>
    The software is provided "AS IS" and "AS AVAILABLE" and with all faults and
    defects without warranty of any kind.
  </p>
  <h2>Limitation of Liability</h2>
  <p>
    In no event shall Quant IP GmbH, nor its directors, employees, partners,
    agents, suppliers, or affiliates, be liable for any indirect, incidental,
    special, consequential or punitive damages, including without limitation,
    loss of profits, data, use, goodwill, or other intangible losses.
  </p>

  <p>
    The information contained herein does not constitute the provision of
    investment advice. It is not intended to be and should not be construed as a
    recommendation, offer or solicitation to acquire, or dispose of, any of the
    financial instruments and/or securities mentioned in this document and will
    not form the basis or a part of any contract or commitment whatsoever.
    Investors should seek independent professional advice and draw their own
    conclusions regarding suitability of any transaction including the economic
    benefits, risks, legal, regulatory, credit, accounting and tax implications.
    The past performance of financial instruments is not indicative of future
    results. No assurance can be given that any financial instrument or issuer
    described herein would yield favourable investment results. Any forecasts or
    price targets shown for companies and/or securities discussed in this
    document may not be achieved due to multiple risk factors including without
    limitation market volatility, sector volatility, corporate actions, the
    unavailability of complete and accurate information and/or the subsequent
    transpiration that underlying assumptions made by Quant IP or by other
    sources relied upon in the document were inapposite.
  </p>
  <p>
    The information in this report is based on public data obtained from sources
    believed by Quant IP to be reliable and in good faith, but no
    representations, guarantees or warranties are made by Quant IP with regard
    to accuracy, completeness or suitability of the data. Quant IP has not
    performed any independent review or due diligence of publicly available
    information regarding an unaffiliated reference asset or index. The opinions
    and estimates contained herein reflect the current judgement of the
    author(s) of the outputs on the date of access to the service and are
    subject to change without notice. Quant IP does not have an obligation to
    update, modify or amend these outputs or to otherwise notify a reader
    thereof in the event that any matter stated herein, or any opinion,
    projection, forecast or estimate set forth herein, changes or subsequently
    becomes inaccurate. Quant IP prepares the outputs, reports and all its
    content to the best of its knowledge and belief. Quant IP has exercised due
    diligence in the selection of raw data providers, the development of
    analytical tools, and the verification of the results of this report.
  </p>
  <p>
    Although Quant IP shall obtain information for inclusion in the service from
    sources that Quant IP considers reliable, the data, information and all
    content in this report is provided “as is” Neither Quant IP nor any of its
    affiliates, any of its officers and employees or any of its direct or
    indirect information providers nor any other third party involved in or
    related to the compilation of or creation of any report or output, or any
    component thereof makes any representation or warranty of any kind either
    express or implied, with respect to the data, information and all content,
    the results to be obtained by the use thereof or any other matter. Further
    Quant IP expressly disclaims any and all implied warranties, including
    without limitation, warranties of originality, accuracy, completeness,
    timeliness, merchantability and fitness for a particular purpose. Neither
    Quant IP nor any of its respective directors, officers or employees shall in
    any event have any liability to the recipient(s) of any output or any third
    party for any damages of any kind arising out of, or in connection with any
    errors, omissions or misinterpretations of content of any output from
    Mellon. Quant IP may provide hyperlinks to websites of entities mentioned in
    any view or report, however the inclusion of a link does not imply that
    Quant IP endorses, recommends or approves any material on the linked page or
    accessible from it. Quant IP does not accept responsibility whatsoever for
    any such material, nor for any consequences of its use. This service is for
    the use of the addressees only and may not be reproduced, redistributed or
    passed on to any other person or published, in whole or in part, for any
    purpose, without the prior, written consent of Quant IP. The manner of
    distributing any information obtained from Mellon may be restricted by law
    or regulation in certain countries, including the United States. Persons
    into whose possession this tool may come are required to inform themselves
    about and to observe such restrictions. By accepting this license, a
    recipient hereof agrees to be bound by the foregoing limitations.
  </p>
  <p>
    Additional Information for certain countries: United States: This service is
    intended for distribution in the United States solely to “institutional
    investors” and “major US institutional investors”, as defined in Rule 15a-6
    under the Securities Exchange Act of 1934. United Kingdom: This service is
    directed exclusively to eligible counterparties and professional clients. It
    is not directed to retail clients. No persons other than an eligible
    counterparty or a professional client should read or rely on any information
    in this document. Switzerland: The financial instruments mentioned in this
    service do not constitute a participation in a collective investment scheme
    in the sense of the Swiss Collective Investment Schemes Act (CISA). This
    service, the information contained herein as well as any other publication
    in connection with the financial instruments mentioned herein may be
    distributed exclusively to qualified investors as defined in the CISA and
    may only be made available to such qualified investors.
  </p>

  <h2>Governing Law</h2>
  <p>
    This Agreement shall be governed by the laws of the Federal Republic of
    Germany.
  </p>

  <h2>Contact Information</h2>
  <p>
    If you have any questions about this Agreement, please contact us at
    <a href="mailto:info@quant-ip.com." style="color: blue"
      >info&#64;quant-ip.com</a
    >.
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn btn-primary"  (click)="close()">Close</button>
</mat-dialog-actions>
