import { Routes } from '@angular/router';
import { LoginPageComponent } from './core/components/login/login-page/login-page.component';
import { HomeComponent } from './core/components/home/home.component';
import { CompanySearchComponent } from './features/company-search/components/company-search/company-search.component';
import { TestComponent } from './core/components/test/test.component';
import { ThematicHomeComponent } from './features/thematic-search/components/thematic-home/thematic-home.component';
import { CustomSearchComponent } from './features/thematic-search/components/custom-search/custom-search.component';
import { ThemeCatalogueComponent } from './features/thematic-search/components/theme-catalogue/theme-catalogue.component';
import { ThemeTrendsComponent } from './features/idea-generation/components/theme-trends/theme-trends.component';
import { IdeaGenerationHomeComponent } from './features/idea-generation/components/idea-generation-home/idea-generation-home.component';
import { ResultsMainPageComponent } from './features/results/components/results-main-page/results-main-page.component';
import { AuthGuard } from './core/guards/auth.guard';


export const routes: Routes = [
  { path: '', component: LoginPageComponent },
  { path: 'login', component: LoginPageComponent },
  { path: 'home', component: HomeComponent,canActivate: [AuthGuard] },
  { path: 'company-search', component: CompanySearchComponent ,canActivate: [AuthGuard]},
  { path: 'thematic-search', component: ThematicHomeComponent,canActivate: [AuthGuard] },
  { path: 'thematic-search/custom', component: CustomSearchComponent,canActivate: [AuthGuard] },
  { path: 'thematic-search/catalogue', component: ThemeCatalogueComponent,canActivate: [AuthGuard] },
  { path: 'idea-generation', component: IdeaGenerationHomeComponent ,canActivate: [AuthGuard]}
  , { path: 'test', component: TestComponent,canActivate: [AuthGuard] },

  { path: 'results', component: ResultsMainPageComponent,canActivate: [AuthGuard] },


];

