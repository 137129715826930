<app-main-navbar></app-main-navbar>

<div class="container-fluid">
  <div class="row mt-5">
    <div class="col">
      <div class="search-form">
        <form [formGroup]="searchForm">

          <input class="form-control" type="text" id="searchTerm" formControlName="searchTerm" placeholder="Enter Keywords">
          &nbsp;
        </form>
      </div>

    </div>
  </div>
  <div class="row">

    <div class="col mt-3">

      <div class="col-auto" style="display: flex;justify-content: center;" *ngIf="themes.length<1">
        <div class="spinner-grow text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="card mt-4" *ngIf="themes.length>0">
        <table class="table table-condensed table-hover">
          <thead>
            <tr>
              <th scope="col">
                <div class="d-flex align-items-center">
                  <mat-slide-toggle [(ngModel)]="favoriteFilter" (change)="filterThemes()"></mat-slide-toggle>
                  <span class="ms-2">Show Only Favorites</span>
                </div>
              </th>

              <!-- Category Filter -->
              <th scope="col">
                <mat-form-field appearance="fill" class="w-20">
                  <mat-label>Category</mat-label>
                  <mat-select [formControl]="categoryFilters" (selectionChange)="filterThemes()" multiple>
                    <mat-option *ngFor="let category of categoryOptions" [value]="category">
                      {{ category }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </th>

              <th scope="col">Name</th>

              <!-- Class Filter -->
              <th scope="col">
                <mat-form-field appearance="fill" class="w-20">
                  <mat-label>Class</mat-label>
                  <mat-select [formControl]="classFilters" multiple (selectionChange)="filterThemes()">
                    <mat-option *ngFor="let classOption of classOptions" [value]="classOption">
                      {{ classOption }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </th>

              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let theme of filteredData  | paginate: {id:'pag_1', itemsPerPage: 10, currentPage: paginator_1_page }">
              <td>
                <button (click)="toggleFavorite(theme)" [disabled]="disableFavoritesButton" class="btn btn-light"
                  style="border: none;">
                  <i class="bi" [ngClass]="theme.favorite ? 'bi-star-fill text-warning' : 'bi-star'"></i>
                </button>
              </td>

              <td>{{theme.category}}</td>
              <td>{{theme.name}}</td>
              <td>{{theme.class_}}</td>

              <td>
                <button class="btn search-button"  (click)="addTheme(theme)">
                  Submit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination-controls id="pag_1" [maxSize]="5" (pageChange)="paginator_1_page = $event"
          style="align-items: flex-start;">
        </pagination-controls>

      </div>
      <br><br>
      <div *ngIf="selected_themes.length>0">
        <span class="mt-4" *ngIf="selected_themes.length>0" styles="font-size:18px"> <strong>
            Selected Themes
          </strong>
        </span>
        <br>

        <table *ngIf="selected_themes.length>0" class="table table-condensed table-hover">
          <thead>
            <tr>
              <th scope="col">
                Class
              </th>
              <th scope="col">
                Category

              </th>
              <th scope="col">Name</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let theme of selected_themes  | paginate: {id:'pag_2', itemsPerPage: 10, currentPage: paginator_2_page }">

              <td>{{theme.class_}}</td>
              <td>{{theme.category}}</td>
              <td>{{theme.name}}</td>

              <td><i class="bi bi-plus-circle" (click)="removeTheme(theme)"></i></td>
            </tr>
          </tbody>
        </table>
        <pagination-controls id="pag_2" [maxSize]="5" (pageChange)="paginator_2_page = $event"
          style="align-items: flex-start;">
        </pagination-controls>

      </div>
      <div class="submit_button mt-4" *ngIf="selected_themes.length>0">
        <button type="button" class="btn " style="background-color:#9681d1" (click)="submitThemes()">
          Submit
        </button>
      </div>
    </div>

  </div>

</div>
