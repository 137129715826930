// results-main-page.component.ts
import { Component } from '@angular/core';
import { combineLatest, Observable, startWith, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, Tab } from '../../../../state/app.state';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { TabComponent } from '../tab/tab.component';
import { GeneralInnovationActivityComponent } from '../result-sections/general-innovation-activity/general-innovation-activity.component';
import { CommonModule } from '@angular/common';
import { selectActiveSideTab, selectActiveTabDetails, selectActiveTabID, selectActiveTabSidenavData, selectMainState, selectSidenavDataByTabIdAndKey, selectTabList } from '../../../../state/selectors';
import { MainNavbarComponent } from '../../../../core/components/main-navbar/main-navbar.component';
import { PatentViewComponent } from '../patent-view/patent-view.component';
import { Router, RouterOutlet } from '@angular/router';
import { InnovationActivityComponent } from '../result-sections/general/innovation-activity/innovation-activity.component';
import { TechnologyFootprintComponent } from '../result-sections/general/technology-footprint/technology-footprint.component';
import { TechnologyBreakdownComponent } from '../result-sections/thematic-view/technology-breakdown/technology-breakdown.component';
import { InnovationQualityComponent } from '../result-sections/company-view/innovation-quality/innovation-quality.component';
import { ThematicCompetitionOverviewComponent } from '../result-sections/thematic-view/thematic-competition-overview/thematic-competition-overview.component';
import { PeerGroupComponent } from '../result-sections/company-view/peer-group/peer-group.component';
import { ResultsMainHeaderComponent } from '../results-main-header/results-main-header.component';
import { CompanyOverviewComponent } from '../result-sections/company-view/company-overview/company-overview.component';

@Component({
  selector: 'app-results-main-page',
  standalone: true,
  templateUrl: './results-main-page.component.html',
  styleUrls: ['./results-main-page.component.css'],
  imports:[SidebarComponent,TabComponent,GeneralInnovationActivityComponent,CommonModule,MainNavbarComponent,PatentViewComponent,RouterOutlet,InnovationActivityComponent,TechnologyFootprintComponent,TechnologyBreakdownComponent,InnovationQualityComponent,ThematicCompetitionOverviewComponent,PeerGroupComponent,ResultsMainHeaderComponent,CompanyOverviewComponent]
})
export class ResultsMainPageComponent {

  tabs$!: Observable<Tab[]>;
  activeTab$!:Observable<any>;
  activeSideTab$!:Observable<any>;
  activeTabDetails$!: Observable<Tab | undefined>;
  constructor(private store: Store<AppState>,  private router: Router) {

  }

  ngOnInit(): void {
    this.tabs$ = this.store.select(selectTabList);
    this.activeTab$ = this.store.select(selectActiveTabID);
    this.activeSideTab$ = this.store.select(selectActiveSideTab);
    this.activeTabDetails$ = this.store.select(selectActiveTabDetails);
    this.tabs$.subscribe((tabs) => {
      if (!tabs || tabs.length === 0) {
        this.router.navigate(['/home']); // Navigate to home if tabs are empty
      }
    });
  }

  getParams() {
    return this.activeTabDetails$; // returns an observable that can be used in the template
  }
}
