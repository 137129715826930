import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICompanyResponse } from '../../../core/models/company.model';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanySearchService {

  constructor(private http: HttpClient) { }


  companySearch(
    name?: string,
    isin?: string,
    url?: string|null
  ) {
    let params = new HttpParams()
    .set("num_matches", "100")
    .set("name", name ?? '')
    .set("isin", isin ?? '')
    .set("url", url ?? '');


    return this.http.get<ICompanyResponse>("/backend/company/search", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("current_user_token")}`,
      },
      params: params,
      responseType: "json",
    });
  }


}
