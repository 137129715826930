<div class="container-fluid">
  <div class="row">
      <div class="col">
        <div class="card top-themes-card" *ngIf="topThemes" >
          <div class="top-themes-container">
            <h3>Top 3 Themes</h3>
            <table class="table   table-hover table-condensed ">
              <thead >
                <tr style="background-color: #009879;">
                  <th>Theme</th>
                  <th>Growth</th>
                  <th>Growth Percentage</th>
                  <th>Keywords</th>
                  <th>Top Companies</th>
                  <th>Search</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let themeInfo of topThemes">
                  <td>{{themeInfo.theme}}</td>
                  <td>{{themeInfo.value}}</td>
                  <td>{{themeInfo.growthPercentage}}%</td>
                  <td [innerHTML]="themeInfo.keywords"></td>
                  <td [innerHTML]="themeInfo.companies"></td>
                  <td>
                    <button class="btn search-button"  (click)="submitTheme(themeInfo)">
                      Search
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
</div>
