import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IdeaGenStateService {
  // Holds the selected option ('topThemes' or 'themeTrends')
  private selectedOptionSubject = new BehaviorSubject<string>('themeTrends');
  // Holds the selected market ('developed' or 'global')
  private selectedMarketSubject = new BehaviorSubject<string>('developed');

  selectedOption$ = this.selectedOptionSubject.asObservable();
  selectedMarket$ = this.selectedMarketSubject.asObservable();

  constructor() {}

  setSelectedOption(option: string, market: string) {
    this.selectedOptionSubject.next(option);
    this.selectedMarketSubject.next(market);
  }


}
