import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EULADialogComponent } from '../login/eula-dialog/eula-dialog.component';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
@Component({
  selector: 'app-test',
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule,MatButtonModule, MatDividerModule, MatIconModule],
  templateUrl: './test.component.html',
  styleUrl: './test.component.css'
})
export class TestComponent {


  sidenavOpen = true;
  selectedContent: string = 'trends';

  toggleSidenav() {
    this.sidenavOpen = !this.sidenavOpen;
  }

  showContent(content: string) {
    this.selectedContent = content;
  }
}
