import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import * as PlotlyJS from 'plotly.js-dist-min';
import { PlotlyModule } from 'angular-plotly.js';
import { importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { SearchEffects } from './app/state/search_effect';
import { appConfig } from './app/app.config';

PlotlyModule.plotlyjs = PlotlyJS;

bootstrapApplication(AppComponent, {
  providers: [
    // importProvidersFrom(
    //   EffectsModule.forRoot([AppEffects, SearchEffects]),
    // ),
    ...appConfig.providers,  // Spread appConfig providers here
  ],
})
  .catch((err) => console.error(err));
