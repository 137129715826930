import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { selectTabList } from '../../../state/selectors';
import { AppState, Tab } from '../../../state/app.state';


@Component({
  selector: 'app-main-navbar',
  standalone: true,
  imports: [RouterOutlet, CommonModule, RouterLink, RouterLinkActive],
  templateUrl: './main-navbar.component.html',
  styleUrls: ['./main-navbar.component.css'],
})
export class MainNavbarComponent {
  // Observable to track tabs presence
  hasTabs$: Observable<boolean>;

  constructor(private store: Store<AppState>,private router:Router) {

    this.hasTabs$ = this.store.select(selectTabList).pipe(
      map((tabs: Tab[]) => tabs && tabs.length > 0)
    );
  }

  logout() {
    localStorage.removeItem("current_user_token");
    localStorage.removeItem("timestamp");
    localStorage.removeItem("user");
    localStorage.removeItem("roles");
    localStorage.removeItem("allow_sampling");
    this.router.navigate(['/login']);
  }

}
