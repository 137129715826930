<div class="container-fluid">
  <div class="row">
    <h1 style="font-size: 24px; color:black; text-align: left;margin-left:20px;margin-right:20px;">
      {{ tab_name }}
    </h1>
    <div class="col" style="margin:20px;">
      <div class="card patent_rating_card">
        <h3>Innovation Quality Over Time</h3>
        <div class="spinner-border text-primary" role="status"
          *ngIf="innovation_quality_data.length==0 && !innovation_quality_data_loading_failed">
          <span class="visually-hidden">Loading...</span>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="innovation_quality_data_loading_failed">
          <p>
            Unfortunately, we weren't able to load the data for innovation quality over time. Please try again later, or
            for more assistance, please contact
            <a href="mailto:info@quant-ip.com" style="color: blue">info&#64;quant-ip.com</a>.
          </p>
        </div>
        <div *ngIf="showinnovationQualityGraph" class="patent_rating_container">
          <plotly-plot class="patent_rating_plot" [data]="innovationQualityGraph.data"
            [layout]="innovationQualityGraph.layout"></plotly-plot>
          <img class="patent_rating" src={{patent_rating_image_url}} alt="">
        </div>
      </div>
      <div style="margin:10px;">
        Combining expert domain knowledge with machine learning, Quant IP created Quant IP Quality Score, the first
        indicator built on predictions of future patent success and therefore applicable from the first day of
        publication.

        <p>
          Quant IP Quality Score combines various indicators into one quality assessment quantifying the quality of a
          company’s patents and the whole portfolio.




        </p>
        <p>
          Grant Robustness: The average probability for a patent to be granted in 5 major jurisdictions (USA, EU, China,
          Japan, Korea), evaluated at the time of publication of the patent application.
        </p>
        <p>
          Market Potential: The predicted number of markets the innovation will be filed in, 2 years after initial
          publication date.
        </p>
        <p>
          Citation Potential: The predicted number of forward citations an invention will have received 5 years after
          initial publication. Predicted scores are normalized within year and IPC batches, and aggregated into the
          final Quality Score.
        </p>
      </div>
    </div>

  </div>

</div>