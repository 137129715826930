import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl,FormGroup, Validators } from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';
import { CompanySearchService } from '../../services/company-search.service';
import { ICompany } from '../../../../core/models/company.model';
import {NgxPaginationModule} from 'ngx-pagination';
import { MainNavbarComponent } from '../../../../core/components/main-navbar/main-navbar.component';
import { submitSearch } from '../../../../state/action';
@Component({
  selector: 'app-company-search',
  standalone: true,
  imports: [ReactiveFormsModule,CommonModule,NgxPaginationModule,MainNavbarComponent
  ],
  templateUrl: './company-search.component.html',
  styleUrl: './company-search.component.css'
})
export class CompanySearchComponent {

constructor(private companyService:CompanySearchService,private store:Store){}
paginator_1_page:number=1;
paginator_2_page:number=1;
showSpinner:boolean = false;
  companySearchForm = new FormGroup({
    name: new FormControl(),
    isin: new FormControl(),
    url: new FormControl("", [
      Validators.pattern(
        "^(https?:\/\/)?(www\.)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$"
      ),
    ]),
  });


  companies:ICompany[]=[];
  companySearch(){
    this.showSpinner=true;
   this.companyService.companySearch(this.companySearchForm.value.name,this.companySearchForm.value.isin,this.companySearchForm.value.url).subscribe({
    next:(res)=> {
      this.companies= res.payload;
      this.showSpinner=false;
    },error:()=>{
      alert('Search Failed!');
      this.showSpinner=false;

    }
   })
  }


  selected_companies:ICompany[]=[];
  addCompany(selected:ICompany){
    let exists = this.selected_companies.some(el=>el.entityid === selected.entityid);
    //todo:remove this to make selection of multiple companies

    if(!exists){
      this.selected_companies.push(selected);
      this.submitCompanies();
    }

  }
  removeCompany(selected:ICompany){
    this.selected_companies = this.selected_companies.filter((el)=>{
      return el.entityid!=selected.entityid;
    })

  }

  submitCompanies(){
    const searchParams = {'companies':this.selected_companies};
    // console.log('submit from companies',searchParams);
   this.store.dispatch(submitSearch({searchParams,searchType:'company'}));
  }

}
