<div class="container">
  <h4 class="heading">Idea Generation</h4>
  <hr />
  <div class="row">
    <div class="col">
      <h3 class="heading">Top Themes</h3>
      <ul class="list">
        <li (click)="handleOptionClick('topThemes', 'developed')"
          [ngClass]="{'list-item-active': isActive('topThemes', 'developed')}" class="list-item">
          Developed Markets
        </li>
        <li (click)="handleOptionClick('topThemes', 'global')"
          [ngClass]="{'list-item-active': isActive('topThemes', 'global')}" class="list-item">
          Global Markets
        </li>
      </ul>
<br><br>
      <h3 class="heading">Theme Trends</h3>
      <ul class="list">
        <li (click)="handleOptionClick('themeTrends', 'developed')"
          [ngClass]="{'list-item-active': isActive('themeTrends', 'developed')}" class="list-item">
          Developed Markets
        </li>
        <li (click)="handleOptionClick('themeTrends', 'global')"
          [ngClass]="{'list-item-active': isActive('themeTrends', 'global')}" class="list-item">
          Global Markets
        </li>
      </ul>
    </div>
  </div>

</div>
