<div class="container-fluid">

  <div class="row main-row">
    <div class="col-4 logo-container">
      <div class="logo-text-image-container">
        <img src="assets/QuantIP_Logo.svg" class="logo-img">
        <p class="mellon">“Mellon - Innovation Analytics Platform”
        </p>
      </div>
      <div class="vr"></div>
    </div>

    <div class="col-8 modules-column">
      <div class="card" (click)="openCompanySearch()">
        <div class="image-container">
          <img  src="/assets/company_search_icon.svg" class="card-img-top" alt="...">
        </div>
        <div class="card-body">
          <h5 class="card-title">Company Search</h5>
          <p class="card-text" >Evaluate the innovative strength of companies of interest and benchmark them against competition.
          </p>
        </div>
      </div>


      <div class="card" (click)="openThematicSearch()">
        <div class="image-container">
          <img src="/assets/thematic_search_icon.svg" class="card-img-top" alt="...">
        </div>
        <div class="card-body">
          <h5 class="card-title">Thematic Search</h5>
          <p class="card-text">Search for technology-based investment themes and find innovation leaders in each area.</p>
        </div>
      </div>

      <div class="card" (click)="openIdeaGeneration()">
        <div class="image-container">
          <img src="/assets/idea_generation_icon.svg" class="card-img-top" alt="..." style="    width: 90px;
">
        </div>
        <div class="card-body">
          <h5 class="card-title">Idea Generation</h5>
          <p class="card-text">Find trending themes among the innovation landscape and high-potential investment opportunities.</p>
        </div>
      </div>

    </div>
  </div>
  <div class="row  wave-row">
    <div class="col p-0">
      <img src="assets/wave.svg" class="img-fluid wave-img">
    </div>
  </div>

</div>
