<div class="container-fluid">
  <div class="row">
    <h1 style="font-size: 24px; color:black; text-align: left;margin-left:20px;margin-right:20px;">
      {{ tab_name }}
    </h1>

    <div class="col m-10" style="margin:20px;">
      <div>
        The table below shows companies within the same GICS peer group of the company, ranked based on their innovative output. 
      </div>
      <br>

      <div class="alert alert-info" role="alert" *ngIf="!showResults && !competitors_list_loading_failed">
        <p>
          This result is currently available only for companies classified under the GICS. Stay tuned as we work on adding this feature for all companies.
        </p>
      </div>

      <div class="card keywords" style="padding:20px;" *ngIf="showResults">


        <h3>Detailed Technology Benchmark</h3>
        <br>

        <div class="alert alert-danger" role="alert" *ngIf="showResults && competitors_list_loading_failed">
          <p>
            Unfortunately, we weren't able to load the results. Please try again later, or for more assistance, please
            contact
            <a href="mailto:info@quant-ip.com" style="color: blue">info&#64;quant-ip.com</a>.
          </p>
        </div>

        <div class="spinner-border text-primary" role="status" *ngIf="showResults &&competitors_list.length==0 && !this.competitors_list_loading_failed">
          <span class="visually-hidden">Loading...</span>
        </div>
        <table class="table   table-hover table-condensed " *ngIf="showResults && competitors_list.length>0">
          <thead>
            <tr style="background-color: #009879;">
              <th scope="col">Rank</th>
              <th scope="col">Company</th>
              <th scope="col">Region</th>
              <th scope="col">Portfolio Size (20y)</th>
              <th scope="col">Absolute Growth (3y)</th>
              <th scope="col">Relative Growth (3y)</th>
              <th scope="col">Quality Score (3y)</th>
              <!-- <th>Count</th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let company of competitors_list | paginate: {id:'pag_1', itemsPerPage: 20, currentPage: paginator_1_page }">
              <td>{{company.rank}}</td>
              <td>{{company.entity_name}}</td>
              <td>{{company.region}}</td>
              <td>{{company.invention_count_20y | number : '1.0-1'}}</td>

              <td>{{company.invention_count_3y | number : '1.0-1'}}</td>
              <td>{{company.relative_growth_3y | number : '1.0-1'}}</td>
              <td>{{company.quality_average_3y | number : '1.0-1'}}</td>


            </tr>
          </tbody>
        </table>
        <pagination-controls id="pag_1" [maxSize]="5" (pageChange)="paginator_1_page = $event"
          style="align-items: flex-start;" *ngIf="competitors_list.length>0">
        </pagination-controls>


      </div>
    </div>





  </div>
</div>
