<div *ngIf="(tabList$ | async)?.length! > 0" class="sidebar-container">

  <h3 class="main-heading" >{{ (activeSearchType$ | async) === 'company' ? 'Company View' : 'Thematic View' }}</h3>
  <hr class="main-hr" />

  <ng-container *ngFor="let option of sidebarItems[(activeSearchType$ | async) === 'company' ? 'Company View' : 'Thematic View']">

    <div class="sidebar-header">
      <h4 class="heading">{{ option.display }}</h4>
    </div>


    <ul class="list" *ngIf="option.subOptions">
      <li *ngFor="let subOption of option.subOptions"
          (click)="onSidebarOptionClick($event, subOption.value)"
          [ngClass]="{'list-item-active': isActive( subOption.value)}"
          class="list-item">
        <i class="bi bi-folder"></i>
        {{ subOption.display }}
      </li>
    </ul>


    <div *ngIf="!option.subOptions" class="list">
      <li (click)="onSidebarOptionClick($event, option.value)"
          [ngClass]="{'list-item-active': isActive(option.value)}"
          class="list-item">
        <i class="bi bi-folder"></i>
        {{ option.display }}
      </li>
    </div>
    <hr />
  </ng-container>
</div>
