import { Component, OnInit } from '@angular/core';
import { IdeaGenStateService } from '../../idea-gen-state.service';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-idea-generation-sidebar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  activeTab: { type: string, market: string } = { type: 'themeTrends', market: 'developed' };
  private subscriptions: Subscription = new Subscription();

  constructor(private stateService: IdeaGenStateService) {}

  ngOnInit() {
    // Subscribe to the state service to get the current selected values
    this.subscriptions.add(
      this.stateService.selectedOption$.subscribe(option => {
        this.activeTab.type = option;
      })
    );

    this.subscriptions.add(
      this.stateService.selectedMarket$.subscribe(market => {
        this.activeTab.market = market;
      })
    );
  }

  isActive(type: string, market: string): boolean {
    return this.activeTab.type === type && this.activeTab.market === market;
  }

  handleOptionClick(option: string, market: string) {
    // Set the active tab and notify the state service
    this.activeTab = { type: option, market: market };
    this.stateService.setSelectedOption(option, market);
  }

  ngOnDestroy() {
    // Cleaning up subscriptions to avoid memory leaks
    this.subscriptions.unsubscribe();
  }
}
