import { addTab } from './action';

import { Action, createReducer, on } from '@ngrx/store';
import { changeActiveTab, changeActiveSideTab, loadSidenavData } from './action';
import { AppState, MainState, Tab } from './app.state';
import * as AppStateActions from './action';


export const initialMainState: MainState = {
  activeTabId: '',
  activeSideTab: '',
  activeTab: null
};


export const mainStateReducer =createReducer(
  initialMainState,
  on(AppStateActions.changeActiveTab,(state,{tabId})=>({
    ...state, activeTabId:tabId
  })),
  on(AppStateActions.changeActiveSideTab,(state,{sideTab})=>({
    ...state,activeSideTab:sideTab
  })),  on(AppStateActions.setActiveTab, (state, { tab }) => ({
    ...state,
    activeTabId: tab.tabId,
    activeTab: tab
  }))
);


